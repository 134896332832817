import { computed, ref } from "vue";

import { useStore } from "@/use/useStore";
import schoolMenuService from "@/services/school-menu.service";
import { TSchoolMenuFormatted } from "@/types/school-menu";

export function useSchoolMenu() {
  const store = useStore();

  const schoolMenu = ref<TSchoolMenuFormatted>(null);

  const hasMenu = computed(
    () => !!(schoolMenu.value?.sets.length || schoolMenu.value?.types.length)
  );

  async function fetchSchoolMenu() {
    const menu = await schoolMenuService.fetchMenu(store.currentUser.value!.id);
    schoolMenu.value = menu;
  }

  return {
    schoolMenu,
    hasMenu,
    fetchSchoolMenu,
  };
}
