import axios from "axios";
import { groupBy, uniqBy, upperFirst } from "lodash";

import * as Api from "@/api";
import {
  IFoodset,
  ISchoolMenuFood,
  ISchoolMenuType,
  TSchoolMenuFormatted,
} from "@/types/school-menu";

const getUniqFoods = (foods: ISchoolMenuFood[]) =>
  uniqBy(foods, (f) => [f.name, f.price, f.weight].join());

const getVisibleFoods = (foods: ISchoolMenuFood[]) =>
  foods.filter((f) => f.visible);

const getComplexPositions = (types: ISchoolMenuType[]) =>
  types
    .map((t) => t.foods)
    .flat()
    .filter((f) => f.setId > 0);

const getFoodset = (sets: IFoodset[], id: number) =>
  sets.find((s) => s.id === id);

async function fetchMenu(userId: number): Promise<TSchoolMenuFormatted> {
  try {
    const [{ data: menu }, { data: foodsets }] = await Promise.all([
      Api.fetchMenu(userId),
      Api.fetchFoodsets(userId),
    ]);

    /**
     * Убираем комплексы, их собирать будем самостоятельно.
     * Получаем только доступные для показа уникальные наименования.
     * Сортируем по приоритету.
     *  */
    const types = menu.types
      .filter((t) => t.name !== "Комплекс" && t.foods.length > 0)
      .map((t) => ({
        ...t,
        foods: getUniqFoods(getVisibleFoods(t.foods))
          .sort((a, b) => (a.name > b.name ? 1 : -1))
          .map((food) => ({ ...food, name: upperFirst(food.name) })),
      }))
      .sort((a, b) => b.priority - a.priority);

    /**
     * Собираем комплексы самостоятельно.
     * Название комплекса берем из списка всех доступных комплексов.
     * Подсчитываем суммарную стоимость комлекса исходя из его наименований.
     */
    const sets = Object.values(
      groupBy(getComplexPositions(menu.types), "setId")
    ).map((foods) => {
      const foodset = getFoodset(foodsets, foods[0].setId);

      return {
        foods,
        sum: foods.reduce((acc, food) => (acc += food.price), 0),
        name: upperFirst(foodset?.name || "Без названия"),
        description: foodset?.description || null,
      };
    });

    return { types, sets };
  } catch (e) {
    if (axios.isAxiosError(e) && e.response?.status === 404) {
      return null;
    }

    throw e;
  }
}

export default {
  fetchMenu,
};
